@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;1,300&family=Source+Serif+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@500&display=swap");
.left {
  display: flex;
  margin-top: 10px;
  gap: 5px;
  margin-left: 100px;
}

.right {
  margin-left: 5px;
  margin-right: 5px;
  gap: 5px;
}

.navbar02 {
  list-style-type: none;
  display: flex;
  gap: 30px;
  margin: 25px;
  font-size: larger;
  margin-right: 200px;
  margin-top: 40px;
  font-family: "Exo";
  font-weight: "600";
  color: rgb(129, 35, 35);
  text-decoration: none;
}

.main-header {
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  margin-bottom: 0px;
}

.logoimg {
  width: 170px;
  height: 90px;
  padding: 5px;
}

.brandName {
  font-family: "Source Serif Pro", serif;
  font-size: 25px;
  margin-left: 35px;
}

@media (max-width: 768px) {
  .main-header {
    display: none;
  }
}

.nav02 {
  color: #54c3bd;
  font-weight: bold;
  padding: 7px;
}

.nav02:hover {
  color: white;
  background-color: #54c3bd;
}
