head-text {
  position: relative;
  color: white;
}

.center__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Sawarabi+Gothic&display=swap");
