@media (max-width: 768px) {
  .top {
    background-color: black;
    position: fixed;
    z-index: 999;
  }
}
@media (min-width: 768px) {
  .mobileTopBar {
    display: none;
  }
}

#topbar22 {
  background-color: black;
  height: 90px;
  position: fixed;
  z-index: 999;
  width: 100%;
}

/* 
.contact-details {
  margin-top: 55px;
  margin-left: 35px;
} */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@500&family=Montserrat:wght@300&family=Sawarabi+Gothic&display=swap");
.brandName01 {
  color: white;
  font-size: 17px;
  margin-left: 50px;
  margin-top: 12px;
  font-weight: "400";
  font-family: "IBM Plex Sans Arabic";
}
