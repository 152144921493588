@media (min-width: 768px) {
  .carosal-div {
    padding: 30px;
    /* padding-right: 60px; */
    align-items: center;
    justify-content: center;
    margin-left: 250px;
    margin-right: 100px;
  }

  #logo-client-imge {
    width: "100px";
    height: "170px";
  }
}

@media (max-width: 768px) {
  #logo-client-imge {
    width: "100px";
    height: "50px";
    padding: "50px";
    margin-right: 100px;
  }
}

.clients {
  background-color: #ceced480;
  opacity: 1;
  background-image: radial-gradient(#9696a3 0.25px, #ffffffd3 0.25px);
  background-size: 5px 5px;
}
