.footer-bs {
  background-color: #222222;
  padding: 150px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 20px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 6px;
}

.contact-details {
  text-align: start;
}

.footer-bs .footer-brand,
.footer-bs .footer-nav,
.footer-bs .footer-social,
.footer-bs .footer-ns {
  padding: 10px 25px;
}
.footer-bs .footer-nav,
.footer-bs .footer-social,
.footer-bs .footer-ns {
  border-color: transparent;
}
.footer-bs .footer-brand h2 {
  margin: 0px 0px 10px;
}
.footer-bs .footer-brand p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}

.footer-bs .footer-nav ul.pages {
  list-style: none;
  padding: 0px;
}
.footer-bs .footer-nav ul.pages li {
  padding: 5px 0px;
}
.footer-bs .footer-nav ul.pages a {
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  text-transform: uppercase;
}
.footer-bs .footer-nav ul.pages a:hover {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.footer-bs .footer-nav h4 {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 10px;
}

.footer-bs .footer-nav ul.list {
  list-style: none;
  padding: 0px;
}
.footer-bs .footer-nav ul.list li {
  padding: 5px 0px;
}
.footer-bs .footer-nav ul.list a {
  color: rgba(255, 255, 255, 0.8);
}
.footer-bs .footer-nav ul.list a:hover {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
}

.footer-bs .footer-social ul {
  list-style: none;
  padding: 0px;
}
.footer-bs .footer-social h4 {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.footer-bs .footer-social li {
  padding: 5px 4px;
}
.footer-bs .footer-social a {
  color: rgba(255, 255, 255, 1);
}
.footer-bs .footer-social a:hover {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

.footer-bs .footer-ns h4 {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 10px;
}
.footer-bs .footer-ns p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}

@media (min-width: 768px) {
  .footer-bs .footer-nav,
  .footer-bs .footer-social,
  .footer-bs .footer-ns {
    border-left: solid 1px rgba(255, 255, 255, 0.1);
  }
}
