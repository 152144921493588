#serviceBox {
  border: 1px solid #40425827;
  padding: "8px";
  height: "800px";
  color: "blue";
}

#content {
  padding: "15px";
  height: "800px";
  color: "blue";
}
