@import url("https://fonts.googleapis.com/css2?family=Sawarabi+Gothic&display=swap");

@media (min-width: 768px) {
  .mobile-profile {
    display: none;
  }
}

.d-flex flex-column mb-3 {
  background-image: url("https://images.pexels.com/photos/188679/pexels-photo-188679.jpeg?auto=compress&cs=tinysrgb&w=1600");
  background-color: rgb(158, 158, 35);
  color: aquas;
}

.inner-div-1 {
  /* background-color: #eff5f5;
  color: #434242; */
  font-family: "Sawarabi Gothic", sans-serif;
  margin-top: "100px";
}
