@media (min-width: 768px) {
  /* #trucks {
    height: "500px";
    object-fit: "cover";
    display: none;
  } */

  .mobile-carosal {
    display: none;
  }
}

.mobile-carosal {
  /* margin-top: 50px; */
  padding-top: 90px;
}
