@media (min-width: 768px) {
  .trucksContainer {
    margin-left: 250px;
    margin-right: 250px;
    padding-top: 100px;
    padding-bottom: 100px;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .trucksContainer {
    padding: 20px;
    padding-top: 150px;
  }
}
