@media (max-width: 768px) {
  .table-images {
    display: none;
  }
  .table-1 {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .table-1 {
    width: 50%;
    margin-top: 70px;
  }
}
