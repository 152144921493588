@media screen and (min-width: 320px) and (max-width: 640px) {
  /* for screens that are at least 320 pixels wide but less than or equal to 640 pixels wide */

  .main-content {
    color: rgb(126, 15, 15);
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .main-content {
    display: none;
  }
}

.main-content {
  background-color: black;
}
