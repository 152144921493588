@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@media (max-width: 768px) {
  .Chairmans-Message {
    display: none;
  }
}

.Arbab-Name {
  padding-top: "20px";
  color: rgb(19, 9, 9);
}
