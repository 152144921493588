@media (max-width: 768px) {
  /* #trucks {
      height: "500px";
      object-fit: "cover";
      display: none;
    } */

  #topbar-desktop {
    display: none;
  }
}

#topbar-desktop {
  background-color: #54c3bd;
  height: 42px;
  position: sticky;
}

.phone02 {
  font-size: 15px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.767);
}
